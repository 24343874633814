<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
  font-size: 12px;
}
#app {
  width: 100%;
  height: 100%;
  min-width: 1300px;
}
@media screen and (max-width: 375px) {
  html,
  body {
    font-size: 12px;
  }
}

@media (min-width: 375px) and (max-width: 1200px) {
  html,
  body {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: 36px;
  }
}
</style>
