import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
/**
 * 避免冗余导航到当前位置：“ /”
 * error： Avoided redundant navigation to current location: "/"
 */
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = [
  {
    path: "/",
    name: "home",
    redirect: "/drug-info",
  },
  {
    path: "/drug-info",
    name: "drugInfo",
    pageName: "药品信息",
    redirect: "/drug-info/first",
    component: (resolve) => require(["@/pharmacywebSrc/views/drugInfo"], resolve),
    children: [
      {
        path: "first",
        name: "first",
        pageName: "商品首页",
        component: (resolve) => require(["@/pharmacywebSrc/views/drugInfo/first"], resolve),
      },
      {
        path: "drugDetails",
        name: "drugDetails",
        pageName: "商品详情",
        component: (resolve) =>
          require(["@/pharmacywebSrc/views/drugInfo/drugDetails"], resolve),
      },
      {
        path: "list/:typeName",
        name: "list",
        pageName: "商品列表",
        component: (resolve) => require(["@/pharmacywebSrc/views/drugInfo/drugList"], resolve),
      },
    ],
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    pageName: "关于我们",
    component: (resolve) => require(["@/pharmacywebSrc/views/aboutUs"], resolve),
  },

  {
    path: "/us",
    name: "us",
    pageName: "关于我们",
    component: (resolve) => require(["@/pharmacywebSrc/views/us"], resolve),
  },
  {
    path: "/company",
    name: "company",
    pageName: "公司简介",
    component: (resolve) => require(["@/pharmacywebSrc/views/company"], resolve),
  }
];

// 向外部跑出router
export default router
